import './App.css';
import config from './config.json';
import React from 'react';
import User from './User.js';
import Subreddit from './Subreddit';
import PostForm from './PostForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

function App() {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    fetch(`${config.SERVER_URL}/me`, {credentials: 'include', mode: 'cors'})
      .then((res) => res.json())
      .then((data) => {
        const subreddits = data.subreddits.map((subreddit) => new Subreddit(subreddit.id, subreddit.name))
        const user = new User(data.id, data.name, subreddits)
        setUser(user)
      })
      .catch(() => setUser(User.GUEST))
  }, []);

  return <UI user={user}/>;
}

function UI(props) {
  const user = props.user;
  if (!user) {
    return (
      <div className='welcome'>
        <Spinner/>
      </div>
    )
  }
  if (user === User.GUEST) {
    return (
      <div className='welcome'>
        <a className='link-dark' href={`${config.SERVER_URL}/login`}>Login</a>
      </div>
    )
  }

  async function submit(state) {
    const resp = await fetch(`${config.SERVER_URL}/post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(state),
    })

    if (!resp.ok) {
      throw new Error(resp.statusText);
    }

    const body = await resp.json()

    return body.url;
  }

  return (
    <div>
      <header>
        <div className={'px-3 py-2 bg-dark d-flex flex-row-reverse'}>
          {user && user !== User.GUEST ? <a className='link-light' href={`${config.SERVER_URL}/logout`}>Logout</a> : ''}
        </div>
      </header>
      <article>
        <Container>
          <Row className='justify-content-md-center'>
            <Col lg='6'>
              <PostForm user={user} onSubmit={submit}/>
            </Col>
          </Row>
        </Container>
      </article>
    </div>
  )
}

export default App;
