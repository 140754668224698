import React from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';

class ToastMessage {
  constructor(message, isError) {
    this.message = message
    this.isError = isError
  }
}

export default class PostForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = PostForm.getInitialState(props)

    this.onSubredditChange = this.onSubredditChange.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
    this.onUrlChange = this.onUrlChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onHideToast = this.onHideToast.bind(this)
  }

  static getInitialState(props) {
    return {
      subreddit: props.user.subreddits ? props.user.subreddits[0].name : '',
      title: '',
      url: '',
      toast: null,
      loading: false,
    };
  }

  isValid() {
    return this.state.title && this.state.subreddit && this.state.url
  }

  onSubredditChange(event) {
    this.setState({subreddit: event.target.value, toast: null});
  }

  onTitleChange(event) {
    this.setState({title: event.target.value, toast: null});
  }

  onUrlChange(event) {
    this.setState({url: event.target.value, toast: null});
  }

  onHideToast(event) {
    this.setState({toast: null});
  }

  onSubmit(event) {
    event.preventDefault()
    const payload = {
      subreddit_name: this.state.subreddit,
      title: this.state.title,
      url: this.state.url,
    }
    this.setState({toast: null, loading: true});
    const self = this
    const doSubmit = async function() {
      try {
        const url = await self.props.onSubmit(payload)
        if (!url) {
          const toast = new ToastMessage('Unknown error', true);
          self.setState({toast: toast});
        } else {
          const toast = new ToastMessage(url, false);
          self.setState({url: '', title: '', toast: toast});
        }
      } catch (e) {
        const toast = new ToastMessage(e.message || 'Unknown error', true);
        self.setState({toast: toast});
      } finally {
        self.setState({loading: false})
      }
    }
    doSubmit()

    return false
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <FloatingLabel
          controlId='floatingInput'
          label='Community'
          className='mb-3'
        >
          <Form.Select id='subreddit' name='subreddit' value={this.state.subreddit}  onChange={this.onSubredditChange}>
            {this.props.user.subreddits.map((subreddit) =>
              <option key={subreddit.id} value={subreddit.name}>{subreddit.name}</option>
            )}
          </Form.Select>
        </FloatingLabel>
        <Row>
          <Col>
            <FloatingLabel
              controlId='floatingInput'
              label='Title'
              className='mb-3'
            >
              <Form.Control type='text' name='title' value={this.state.title} onChange={this.onTitleChange}/>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId='floatingInput'
              label='URL'
              className='mb-3'
            >
              <Form.Control type='text' name='url' value={this.state.url} onChange={this.onUrlChange}/>
            </FloatingLabel>
          </Col>
        </Row>
        <div className='d-flex gap-3 justify-content-end align-items-start'>
          {this.state.toast ? <ResultToast toast={this.state.toast} onHideToast={this.onHideToast}/> : null}
          <Button disabled={!this.isValid() || this.state.loading} type='submit'>
            {this.state.loading ? <Spinner className='spinner-border-sm'/> : null}
            Post
          </Button>
        </div>
      </Form>
    );
  }
}

function Success(props) {
    return (
      <span>New post is created. <a rel='noreferrer' target='_blank' href={props.url}>View it here!</a></span>
    )
}


function ResultToast(props) {
  return (
    <Toast show={true} onClose={props.onHideToast}>
      <Toast.Header>
        <strong className='me-auto'>{props.toast.isError ? 'Error!' : 'Success!'}</strong>
      </Toast.Header>
      <Toast.Body>
        {props.toast.isError ? `An error has occurred: ${props.toast.message}` : <Success url={props.toast.message}/>}
      </Toast.Body>
    </Toast>
  )
}